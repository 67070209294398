import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Image, Container, Group, Grid, Select } from '@mantine/core';
import * as Icon from '@tabler/icons-react';
import zarphLogo from '../../../assets/img/Versão_horizontal_branco_sem_assinatura.svg';
import { login } from '../../client-redux/login/actionCreators';
import { useNavigate } from 'react-router-dom';
import { fetchLogin, LoginBody } from '../../../libraries/login';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import Alert from '../../components/alert';
import { StatusOper } from '../../../libraries/zps';
import { loadLanguageSettings, selectedLang } from '../../client-redux/languages';
import { saveViews, updateActiveTab } from '../../client-redux/sidebar';
import { buildUserMenus, ViewMapProps } from '../../utils/view-map-props';
import { fetchCurrencies } from '../../../libraries/currencies';
import { saveCurrencyInfo } from '../../client-redux/currencies';
import UserIDLoginForm from './user-id-login-form';
import EmailLoginForm from './email-login-form';
import { fetchRegions } from '../../../libraries/region';
import { saveRegionsInfo } from '../../client-redux/regions';
import { fetchShops } from '../../../libraries/shop';
import { saveShopsInfo } from '../../client-redux/shops';
import { fetchEntities } from '../../../libraries/entity';
import { saveEntitiesInfo } from '../../client-redux/entities';
import './styles.scss';

export const Login = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);
  const token = useSelector<AppState, String | undefined>(state => state.login.token);
  const views = useSelector<AppState, ViewMapProps[]>(state => state.sidebar.views);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | undefined>();
  const [entid, setEntid] = useState<string | undefined>();
  const [uid, setUid] = useState<string | undefined>();
  const [password, setPassword] = useState<string>('');
  const [statusOper, setStatusOper] = useState<StatusOper | undefined>();
  const [isUserIDForm, setIsUserIDForm] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('pt-PT');
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);

  const toggleLoginForms = () => {
    setIsUserIDForm(!isUserIDForm);
    setStatusOper(undefined); // Clear the alert state when toggling forms

    if (isUserIDForm) {
      setEmail(undefined);
      setPassword('');
    } else {
      setEntid(undefined);
      setUid(undefined);
    }
  };

  const onEntidChange = (event: any) => {
    setEntid(event.target.value);
    setStatusOper(undefined);
  };

  const onUidChange = (event: any) => {
    setUid(event.target.value);
    setStatusOper(undefined);
  };

  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
    setStatusOper(undefined);
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
    setStatusOper(undefined);
  };

  const authentication = async (params: LoginBody) => {
    await fetchLogin(params)
      .then(async (response: any) => {
        if (response.data?.statusOper.code === 0) {
          dispatch(login(response.data));
          dispatch(selectedLang(response.data?.user?.lang));
          dispatch(saveViews(buildUserMenus(response.data?.user?.permissions)));
          dispatch(loadLanguageSettings());
          fetchEntities().then(entitiesResponse => {
            dispatch(saveEntitiesInfo(entitiesResponse.entities));
          });
          fetchCurrencies().then(currenciesResponse => {
            dispatch(saveCurrencyInfo(currenciesResponse.currencies));
          });
          fetchRegions().then(regionsResponse => {
            dispatch(saveRegionsInfo(regionsResponse.regions));
          });
          fetchShops().then(shopsResponse => {
            dispatch(saveShopsInfo(shopsResponse.shops));
          });
          setLoginSuccess(true);
        }
        setStatusOper(response.data?.statusOper);
      })
      .catch(error => console.log('Error: ', error));
  };

  const toggleAlert = () => {
    setStatusOper(undefined);
  };

  function submitLogin(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    authentication({
      entid: entid ? entid : null,
      uid: uid ? uid : null,
      email: email ? email : null,
      password
    });
  }

  const setLanguage = (value: string) => {
    setSelectedLanguage(value);
    switch (value) {
      case 'en-GB':
        dispatch(selectedLang(2));
        break;
      case 'pt-PT':
        dispatch(selectedLang(1));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!loginSuccess || !token) {
      return;
    }
    if (views && views.length > 0) {
      dispatch(updateActiveTab(views[0].label));
      navigate(views[0].link);
    } else {
      dispatch(updateActiveTab('/profile'));
      navigate('/profile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token, views, loginSuccess]);

  return (
    <Container fluid classNames={{ root: 'login' }}>
      {statusOper?.code && (
        <Alert
          variant="danger"
          visible={true}
          toggleAlert={() => toggleAlert()}
          errorCode={statusOper?.code}
          errorMessage={statusOper?.message}
        />
      )}
      <Group>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Group>
              <Image src={zarphLogo} />
            </Group>
            <Group>
              <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
                <Button onClick={toggleLoginForms}>
                  {isUserIDForm ? `${lang?.LOGIN_EMAIL}` : `${lang?.LOGIN_USERID}`}
                </Button>
              </Grid.Col>
            </Group>

            <form onSubmit={submitLogin}>
              {isUserIDForm ? (
                <UserIDLoginForm
                  onEntidChange={onEntidChange}
                  onUidChange={onUidChange}
                  onPasswordChange={onPasswordChange}
                  entid={entid}
                  uid={uid}
                  password={password}
                />
              ) : (
                <EmailLoginForm
                  onEmailChange={onEmailChange}
                  onPasswordChange={onPasswordChange}
                  email={email}
                  password={password}
                />
              )}

              <Group>
                <Button type="submit">{lang?.LOGIN_BUTTON}</Button>
              </Group>
            </form>

            <Group>
              <Select
                value={selectedLanguage}
                onChange={value => setLanguage(value || '')}
                size="md"
                withCheckIcon={true}
                clearable={false}
                allowDeselect={false}
                data={[
                  { value: 'pt-PT', label: lang?.GLOBAL_LANGUAGE_PT! },
                  { value: 'en-GB', label: lang?.GLOBAL_LANGUAGE_EN! }
                ]}
                leftSection={<Icon.IconLanguageHiragana />}
              />
            </Group>
          </Grid.Col>
        </Grid>
      </Group>
    </Container>
  );
};

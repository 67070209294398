import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, Table, Container, Group } from '@mantine/core';
import { useContextMenu } from 'mantine-contextmenu';
import { useSelector } from 'react-redux';
import { fetchEquipmentList, fetchExistencesReport, IEquipmentListResponse } from '../../../libraries/equipment';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import moment from 'moment';
import ShowEquipment from '../../components/show-equipment';
import PaginationComponent from '../../components/pagination';
import { IShop } from '../../../libraries/shop';
import { ICurrency } from '../../../libraries/currencies';
import { LoginState } from '../../client-redux/login/reducer';
import { delegationCheck, formatCurrAmount, handleShortTransactionType } from '../../utils/script';
import FilterBar from '../../components/filterbar/index';
import * as Icon from '@tabler/icons-react';
import Header from '../../components/util/header';
import Background from '../../components/util/background';
import './styles.scss';

const Equipment = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const login = useSelector<AppState, LoginState>(state => state.login);
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const currencies = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);
  const controller = new AbortController();
  const navigate = useNavigate();
  const { showContextMenu } = useContextMenu();

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [entid, setEntid] = useState<number>(login.user?.entid);
  const [sid, setSid] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState(
    window.innerHeight < 600 ? 5 : Math.floor(window.innerHeight / 55) - 5
  );
  const [limit, setLimit] = useState<number>(itemsPerPage);
  const [, setSelectedSid] = useState<number>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [message, setMessage] = useState<IEquipmentListResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      setOffset(0);
      getEquipmentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  useEffect(() => {
    const updateItemsPerPage = () => {
      setItemsPerPage(window.innerHeight < 600 ? 5 : Math.floor(window.innerHeight / 55) - 5);
    };

    window.addEventListener('resize', updateItemsPerPage);
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  const onSearch = () => {
    setCurrentPage(1);
    setOffset(0);
    setSearchTrigger(true);
  };

  useEffect(() => {
    getEquipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEquipmentList = () => {
    if (!login.token) {
      return;
    }
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);
    fetchEquipmentList(entid, sid, offset, limit, totalRows, controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSelectEquipment = (sid: number) => {
    setSelectedSid(sid);
  };

  const checkStatusForCell = (statusCode: number) => {
    switch (statusCode) {
      case 0:
        return lang?.EQUIPMENT_STATUS_LABEL_NOT_DEFINED;
      case 1:
        return lang?.EQUIPMENT_STATUS_LABEL_DEFINED;
      case 2:
        return lang?.EQUIPMENT_STATUS_LABEL_INICIALIZED;
      case 3:
        return <Group className="onlineStatus">{lang?.EQUIPMENT_STATUS_LABEL_ONLINE}</Group>;
      case 4:
        return <Group className="offlineStatus">{lang?.EQUIPMENT_STATUS_LABEL_OFFLINE}</Group>;
      default:
        return '';
    }
  };

  const checkStatusForRow = (statusCode: number) => {
    switch (statusCode) {
      case 0:
        return false;
      case 1:
        return false;
      case 4:
        return false;
      default:
        return true;
    }
  };

  const checkEquipmentState = (stateCode: number) => {
    switch (stateCode) {
      case 0:
        return <Group className="onlineStatus">{lang?.EQUIPMENT_STATE_BUTTON_IN_SERVICE}</Group>;
      case 1:
        return lang?.EQUIPMENT_STATE_BUTTON_OUT_OF_SERVICE;
      case 2:
        return lang?.EQUIPMENT_STATE_LABEL_OUT_OF_SERVICE_FAKE_NOTE;
      default:
        return '';
    }
  };

  const showEquipment = (eid: number) => {
    setActiveRow(eid);
    setOpenDetails(true);
  };

  const getExistencesReport = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    eid: number,
    period: number,
    controller: AbortController
  ) => {
    event.stopPropagation();
    fetchExistencesReport(eid, period, controller);
  };

  const handleEquipmentPeriod = (period: number) => {
    if (!period || 0) return;

    return period;
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateSid = (newSid: number, callback?: () => void) => {
    setSid(newSid);
    if (callback) {
      callback();
    }
  };

  const updateTableSid = (newSid: number, callback?: () => void) => {
    updateSid(newSid);
    if (callback) {
      callback();
    }
  };

  const changePage = (page: number) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
    setCurrentPage(page);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  useEffect(() => {
    getEquipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit]);

  const handleRowRightClick = (event: React.MouseEvent, eid: number, sid: number) => {
    event.preventDefault();

    showContextMenu([
      {
        key: 'view',
        title: lang?.EQUIPMENT_CONSULT_WORKING_CAPITAL,
        onClick: () =>
          navigate(`/equipment/currency/read/${eid}`, {
            state: { sid }
          })
      },
      {
        key: 'edit',
        title: lang?.EQUIPMENT_EDIT_WORKING_CAPITAL,
        onClick: () =>
          navigate(`/equipment/currency/edit/${eid}`, {
            state: { sid }
          })
      }
    ])(event);
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'equipment' }}>
        {openDetails && activeRow && lang && (
          <ShowEquipment eid={activeRow} openDetails={openDetails} hideBtn={() => setOpenDetails(false)} lang={lang} />
        )}
        <Header pageTitle={lang?.GLOBAL_EQUIPMENT} />

        <FilterBar
          onSearch={onSearch}
          tableLoaded={getEquipmentList}
          filterByEntid={updateTableEntid}
          filterBySid={updateTableSid}
        />

        <Table.ScrollContainer minWidth={500}>
          <Table striped highlightOnHover verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{lang?.EQUIPMENT_ID}</Table.Th>
                <Table.Th>{lang?.GLOBAL_TYPE}</Table.Th>
                <Table.Th>SN</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_DESCRIPTION}</Table.Th>
                <Table.Th>{lang?.USER_DELEGATION}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_COM_STATE}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_STATE_MIN}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_COM_DATE_MIN}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_LAST_OPER_MIN}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_LAST_OPER_DT_MIN}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_SOFTWARE_VERSION_MIN}</Table.Th>

                {login.permissions.hasFinancialConfigPermission() && (
                  <>
                    <Table.Th>{lang?.GLOBAL_PERIOD}</Table.Th>
                    <Table.Th>{lang?.GLOBAL_AMOUNT}</Table.Th>
                    <Table.Th></Table.Th>
                  </>
                )}

                {login.permissions.hasUserConfigPermission() && <Table.Th></Table.Th>}
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading && (!message || searchTrigger) ? (
                <Table.Tr>
                  <Table.Td colSpan={16}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {noResults ? (
                    <Table.Tr>
                      <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                        {lang?.GLOBAL_NO_RESULTS}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    message?.equipments?.map((equipment, index) => {
                      const matchingCurrency = currencies?.find(c => c.cid === equipment?.periodAmounts?.[0]?.cid);

                      return (
                        <Table.Tr
                          key={index}
                          onClick={() => {
                            if (checkStatusForRow(equipment.equipment.status)) {
                              handleSelectEquipment(equipment.equipment.sid);
                              showEquipment(equipment.equipment.eid);
                            }
                          }}
                          onContextMenu={event =>
                            handleRowRightClick(event, equipment.equipment.eid, equipment.equipment.sid)
                          }
                          style={{
                            cursor: checkStatusForRow(equipment.equipment.status) ? 'pointer' : 'not-allowed'
                          }}
                          className={checkStatusForRow(equipment.equipment.status) ? 'online-row' : 'offline-row'}
                        >
                          <Table.Td>{equipment.equipment.eid}</Table.Td>
                          <Table.Td>{equipment.equipment.etid}</Table.Td>
                          <Table.Td>{equipment.equipment.serialnumber}</Table.Td>
                          <Table.Td>{equipment.equipment.description}</Table.Td>
                          <Table.Td>{delegationCheck(equipment.equipment.sid, shops)}</Table.Td>
                          <Table.Td>{checkStatusForCell(equipment.equipment.status)}</Table.Td>
                          <Table.Td>
                            {equipment.equipment.status === 3 && equipment.equipmentState?.equipmentState === 0
                              ? checkEquipmentState(equipment.equipmentState.equipmentState)
                              : ''}
                          </Table.Td>
                          <Table.Td>{moment(equipment.lastComDt).format('DD/MM/YYYY HH:mm:ss')}</Table.Td>
                          <Table.Td>{handleShortTransactionType(lang, equipment.lastOperation)}</Table.Td>
                          <Table.Td>{moment(equipment.lastOperationDt).format('DD/MM/YYYY HH:mm:ss')}</Table.Td>
                          <Table.Td>{equipment.softwareVersion}</Table.Td>
                          {login.permissions.hasFinancialConfigPermission() && (
                            <>
                              <Table.Td>{handleEquipmentPeriod(equipment.period)}</Table.Td>
                              <Table.Td>
                                {matchingCurrency
                                  ? formatCurrAmount(equipment?.periodAmounts?.[0]?.amount || 0, matchingCurrency)
                                  : ''}
                              </Table.Td>
                              <Table.Td>
                                {equipment.period ? (
                                  <div
                                    onClick={event =>
                                      getExistencesReport(event, equipment.equipment.eid, equipment.period, controller)
                                    }
                                  >
                                    <Icon.IconFileTypePdf stroke={2} style={{ cursor: 'pointer' }} />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </Table.Td>
                            </>
                          )}
                          {login.permissions.hasUserConfigPermission() && (
                            <Table.Td>
                              <Icon.IconEdit
                                stroke={2}
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/equipmentConfig/${equipment.equipment.eid}`)}
                              />
                            </Table.Td>
                          )}
                        </Table.Tr>
                      );
                    })
                  )}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        <Group>
          <PaginationComponent
            total={message?.equipments?.length ? Math.ceil(message.equipments.length / limit) : 0}
            statusOperCode={statusOperCode}
            itemsPerPage={limit}
            onPageChange={changePage}
            onResultsChange={resultsSelect}
            initialPage={currentPage}
          />
        </Group>
      </Container>
    </Background>
  );
};

export default Equipment;

import { useState } from 'react';
import { Flex, Table, Title } from '@mantine/core';
import { MeansCash, Values } from '../../../libraries/transactions';
import { checkForZeroQuantity, getCurrencyValue, IQuantities, prepareQuantities } from '../../utils/script';
import { ICurrency } from '../../../libraries/currencies';
import { Language } from '../../languages/languageHandler';
import { ReactComponent as CheckIcon } from '../../../assets/img/check.svg';
import { ReactComponent as XIcon } from '../../../assets/img/x.svg';
import './styles.scss';

interface CurrencyTableProps {
  lang: Language;
  currency: ICurrency;
  label?: string;
  cash?: MeansCash[];
  values?: Values[];
}

const CurrencyTable = ({ lang, label, currency, cash, values }: CurrencyTableProps) => {
  const [nrCoins] = useState<number>(currency.nCoins);
  const [nrBills] = useState<number>(currency.nBills);

  const denominations = prepareQuantities(cash || [], currency);

  const calcCoinsTotalPercentage = (nrCoins: number, nrBills: number) => {
    let total = nrCoins + nrBills;
    return (93 * nrCoins) / total;
  };

  const calcBillsTotalPercentage = (nrCoins: number, nrBills: number) => {
    let total = nrCoins + nrBills;
    return (93 * nrBills) / total;
  };
  const coinsZero = checkForZeroQuantity(0, currency.nCoins, denominations);
  const billsZero = checkForZeroQuantity(currency.nCoins, currency.nCoins + currency.nBills, denominations);

  const sliceZeroQuantities = (quantities: IQuantities[]): IQuantities[] => {
    let newQuantities: IQuantities[] = [];
    if (coinsZero && billsZero) {
      newQuantities = [];
    } else if (coinsZero) {
      newQuantities = quantities.slice(currency.nCoins);
    } else if (billsZero) {
      newQuantities = quantities.slice(0, currency.nCoins);
    }

    return newQuantities;
  };

  const mapTableHeader = (headerCurrency: ICurrency) => {
    if (!headerCurrency || !headerCurrency.denominations) return;

    let headerDenominations = headerCurrency.denominations;

    if (coinsZero && billsZero) {
      headerDenominations = [];
    } else if (coinsZero) {
      headerDenominations = headerDenominations.slice(currency.nCoins);
    } else if (billsZero) {
      headerDenominations = headerDenominations.slice(0, currency.nCoins);
    }

    return headerDenominations.map((denomination, index, denominations) => (
      <Table.Th
        style={{
          width: `${
            denomination.type === 1
              ? calcCoinsTotalPercentage(nrCoins, nrBills) / nrCoins
              : calcBillsTotalPercentage(nrCoins, nrBills) / nrBills
          }%`
        }}
        className={`currencyValueCell${index === 0 ? ' first-cell' : ''}${
          index === denominations.length - 1 ? ' last-cell' : ''
        }`}
      >
        {denomination.value * currency.factor}
      </Table.Th>
    ));
  };

  return (
    <Table.ScrollContainer minWidth={500}>
      {label && <Title order={4}>{label}</Title>}{' '}
      {cash && cash.length > 0 && (
        <Table className="currency-table" withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th rowSpan={2}>{currency.currencyStr}</Table.Th>
              {!coinsZero ? <Table.Th colSpan={nrCoins}>{lang?.GLOBAL_COINS}</Table.Th> : null}
              {!billsZero ? <Table.Th colSpan={nrBills}>{lang?.GLOBAL_BILLS}</Table.Th> : null}
            </Table.Tr>
            <Table.Tr>{mapTableHeader(currency)}</Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            <Table.Tr>
              <Table.Td>{lang.GLOBAL_AMOUNT}</Table.Td>
              {sliceZeroQuantities(denominations).map((denomination, index) => (
                <Table.Td key={`amount-${index}`}>{denomination.amount}</Table.Td>
              ))}
            </Table.Tr>
            <Table.Tr>
              <Table.Td>{lang.GLOBAL_QUANTITY}</Table.Td>
              {sliceZeroQuantities(denominations)?.map((denomination, index) => (
                <Table.Td key={`quantity-${index}`}>{denomination.quantity}</Table.Td>
              ))}
            </Table.Tr>
          </Table.Tbody>
        </Table>
      )}
      {/* Render values table only if the values array has objects */}
      {values && values.length > 0 && (
        <Table>
          <Table className="bills-table" withTableBorder withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{lang?.GLOBAL_DENOMINATION}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_SERIAL_NUMBER}</Table.Th>
                <Table.Th>{lang?.GLOBAL_ACCEPTED}</Table.Th>
                <Table.Th>{lang?.TRANSACTION_SUSPECT}</Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {values?.map((value, index) => (
                <Table.Tr className="read-values-table-row" key={index}>
                  <Table.Td>{getCurrencyValue(value.cid, value.cindex)}</Table.Td>
                  <Table.Td>{value.serial}</Table.Td>
                  <Table.Td>
                    <Flex align="center" justify="center">
                      {!value.rejected ? <CheckIcon className="check" /> : <XIcon className="cross" />}
                    </Flex>
                  </Table.Td>
                  <Table.Td>{value.suspicious ? `${lang.YES}` : '-'}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table>
      )}
    </Table.ScrollContainer>
  );
};

export default CurrencyTable;

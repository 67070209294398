import { Modal } from '@mantine/core';
import './styles.scss';
import { useMediaQuery } from '@mantine/hooks';

const ModalComponent = (props: any) => {
  const isMobile = useMediaQuery('(max-width: 48em)');
  return (
    <Modal
      opened={props.show}
      onClose={props.handleClick}
      title={props.title}
      size={props.size}
      centered
      classNames={{ root: props.className }}
      styles={{
        content: { minHeight: '20%' }
      }}
      fullScreen={isMobile}
    >
      <Modal.Body>{props.content}</Modal.Body>
    </Modal>
  );
};

export default ModalComponent;

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Group, Button, Grid, GridCol, Table, Container } from '@mantine/core';
import { fetchEquipmentByEid, IEquipmentStatusResponse } from '../../../libraries/equipment';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal';
import { handleTransactionType } from '../../utils/script';
import './styles.scss';

interface ShowEquipmentProps {
  eid: number;
  openDetails: boolean;
  hideBtn: () => void;
  lang: Language;
}

const ShowEquipment = ({ eid, openDetails, hideBtn, lang }: ShowEquipmentProps) => {
  const [message, setMessage] = useState<IEquipmentStatusResponse>();
  const [selectedEid] = useState<number>(eid);

  useEffect(() => {
    if (selectedEid) {
      fetchEquipmentByEid(selectedEid).then(response => {
        setMessage(response);
      });
    }
  }, [selectedEid, eid]);

  const getEquipmentStatus = (status?: number) => {
    if (status === undefined) {
      return lang?.EQUIPMENT_STATUS_LABEL_NOT_DEFINED;
    }
    switch (status) {
      case null:
        return lang?.EQUIPMENT_NO_STATUS;
      case 0:
        return lang?.EQUIPMENT_STATUS_LABEL_NOT_DEFINED;
      case 1:
        return lang?.EQUIPMENT_STATUS_LABEL_DEFINED;
      case 2:
        return lang?.EQUIPMENT_STATUS_LABEL_INICIALIZED;
      case 3:
        return lang?.EQUIPMENT_STATUS_LABEL_ONLINE;
      case 4:
        return lang?.EQUIPMENT_STATUS_LABEL_OFFLINE;

      default:
        return `UNKNOWN_STATE_${status}`;
    }
  };

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={`EID: ${selectedEid}`}
        size={'65%'}
        content={
          <>
            <Container classNames={{ root: 'show-equipment-details' }}>
              <Group>
                <Grid gutter="sm">
                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <strong>Status</strong>
                    {lang && (
                      <GridCol className={message?.status?.status === 4 ? 'offlineStatus' : 'onlineStatus'}>
                        {message?.status ? getEquipmentStatus(message?.status.status) : `${lang?.EQUIPMENT_NO_STATUS}`}
                      </GridCol>
                    )}
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <strong>{lang?.EQUIPMENT_STATE}</strong>
                    <GridCol className={message?.status?.inService === false ? 'notInService' : 'inService'}>
                      {message?.status
                        ? message?.status?.inService
                          ? `${lang?.EQUIPMENT_STATE_BUTTON_IN_SERVICE}`
                          : `${lang?.EQUIPMENT_STATE_BUTTON_OUT_OF_SERVICE}`
                        : `${lang?.EQUIPMENT_NO_INFORMATION}`}
                    </GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <strong>{lang?.EQUIPMENT_LAST_OPER}</strong>
                    <GridCol>
                      {message?.status?.lastOperation
                        ? handleTransactionType(lang, message?.status.lastOperation)
                        : `${lang?.EQUIPMENT_NO_INFORMATION}`}
                    </GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <strong>{lang?.EQUIPMENT_LAST_COM_DT}</strong>
                    <GridCol>
                      {message?.status?.lastComDt
                        ? moment(message?.status.lastComDt).format('DD/MM/YYYY HH:mm:ss')
                        : `${lang?.EQUIPMENT_NO_INFORMATION}`}
                    </GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <strong>{lang?.EQUIPMENT_LAST_OPER_DT}</strong>
                    <GridCol>
                      {message?.status?.lastOperationDt
                        ? moment(message?.status.lastOperationDt).format('DD/MM/YYYY HH:mm:ss')
                        : `${lang?.EQUIPMENT_NO_INFORMATION}`}
                    </GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <strong>{lang?.EQUIPMENT_SOFTWARE_VERSION}</strong>
                    <GridCol>{message?.status?.softwareVersion ? message?.status?.softwareVersion : 'N/A'}</GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <strong>{lang?.GLOBAL_IP}</strong>
                    <GridCol>N/A</GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <strong>{lang?.GLOBAL_IP_LOCAL}</strong>
                    <GridCol>{message?.status?.lastLocalIp ? message?.status?.lastLocalIp : 'N/A'}</GridCol>
                  </GridCol>
                </Grid>

                {message?.status?.statusList && (
                  <Group classNames={{ root: 'status-table' }}>
                    <Table.ScrollContainer minWidth={500}>
                      <Table>
                        <Table.Thead>
                          <Table.Tr style={{ backgroundColor: '#004960' }}>
                            <Table.Td></Table.Td>
                            <Table.Td></Table.Td>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          {message?.status?.statusList?.map((status, index) => (
                            <Table.Tr key={index}>
                              <Table.Td style={{ color: '#538539' }}>{lang[status?.idLangKey]}</Table.Td>
                              <Table.Td style={{ color: '#538539' }}>{lang[status?.statusLangKey]}</Table.Td>
                            </Table.Tr>
                          ))}
                        </Table.Tbody>
                      </Table>
                    </Table.ScrollContainer>
                  </Group>
                )}

                <Group className="equip-details-buttons">
                  <Button radius="md">{lang?.EQUIPMENT_STATE_BUTTON_RESTART}</Button>
                  <Button radius="md">{lang?.EQUIPMENT_STATE_BUTTON_SHUTDOWN}</Button>
                  <Button radius="md">{lang?.EQUIPMENT_STATE_BUTTON_OPEN_ESCROW}</Button>
                  <Button radius="md">{lang?.EQUIPMENT_STATE_BUTTON_CLOSE_ESCROW}</Button>
                  <Button radius="md">{lang?.EQUIPMENT_STATE_BUTTON_OUT_OF_SERVICE}</Button>
                  <Button radius="md">{lang?.EQUIPMENT_STATE_BUTTON_IN_SERVICE}</Button>
                  <Button radius="md">VPN Connect</Button>
                  <Button radius="md">VPN Disconnect</Button>
                </Group>
              </Group>
            </Container>
          </>
        }
      />
    </>
  );
};

export default ShowEquipment;

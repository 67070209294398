import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';
import { handleDownload } from '../../app/utils/script';

export type Transaction = {
  bankid: number;
  billsInDoubt: number;
  cashIn?: MeansCash[];
  cashOut?: MeansCash[];
  cid: number;
  code: number;
  coinsInDoubt: number;
  dateTime: Date;
  docCode: string;
  docType: number;
  eid: number;
  entid: number;
  envTotals: number;
  etvid: number;
  gid: number;
  mc: MeansCash[];
  msid: number;
  nseq: number;
  period: number;
  products?: Product[];
  readValues?: Values[];
  shift: number;
  sid: number;
  status: string;
  type: string;
  value: number;
  valueDis: number;
  valueRec: number;
  valueType: string;
  uid: number;
};

export type Product = {
  name: string;
  price: number;
  productid: string;
  quantity: number;
};

export type MeansCash = {
  index: number;
  quantity: number;
  origin: number;
  destiny: number;
};

export type Values = {
  cid: number;
  cindex: number;
  countId: number;
  rejected: boolean;
  serial: string;
  suspicious: boolean;
  suspiciousErrors: string;
};

export interface TransactionResponse extends ApiResponse {
  payment: boolean;
  totalRows: number;
  transactions: Transaction[];
  type: string;
}

export interface FileResult extends ApiResponse {
  fileBytes: string;
  fileName: string;
  type: string;
}

export interface ReportResult extends ApiResponse {
  report: string;
  format: string;
  name: string;
  mime: string;
  type: string;
}

export const fetchTransactionList = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  eid: number,
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController
) => {
  const { data } = await axios.get(
    `${zps_ea_war}/tb401/transaction/?begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&eid=${eid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );

  return data;
};

export const fetchLog = async (eid: number, period: number, code: number, controller: AbortController) => {
  try {
    const response = await axios.get(`${zps_ea_war}/log/?eid=${eid}&period=${period}&code=${code}`, {
      signal: controller.signal
    });

    handleDownload(response.data.fileBytes, response.data.fileName, response.data.fileType);
  } catch (error) {
    console.error('Failed to fetch Log file:', error);
  }
};

export const fetchTransactionXLS = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  eid: number,
  controller: AbortController
) => {
  try {
    const response = await axios.get(
      `${zps_ea_war}/tb401/transaction/report/list/?format=xls&begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&eid=${eid}`,
      {
        signal: controller.signal
      }
    );
    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

export const fetchAPMTransactionsXLS = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  controller: AbortController
) => {
  try {
    const response = await axios.get(
      `${zps_ea_war}/apm/transaction/report/?format=xls&begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}`,
      {
        signal: controller.signal
      }
    );
    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

export const fetchAPMPeriodsXLS = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  controller: AbortController
) => {
  try {
    const response = await axios.get(
      `${zps_ea_war}/apm/period/report/?format=xls&begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}`,
      {
        signal: controller.signal
      }
    );
    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

export const fetchAPMPeriodsPDF = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  controller: AbortController
) => {
  try {
    const response = await axios.get(
      `${zps_ea_war}/apm/period/report/?format=pdf&begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}`,
      {
        signal: controller.signal
      }
    );
    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Container, Group, GridCol, Grid, Button, Title, Accordion, Flex } from '@mantine/core';
import { Transaction } from '../../../libraries/transactions';
import { Language } from '../../languages/languageHandler';
import { formatCurrAmount, getShopName, handleTransactionMethod, handleTransactionType } from '../../utils/script';
import ModalComponent from '../modal';
import CurrencyTable from '../util/currency-table';
import { ICurrency } from '../../../libraries/currencies';
import ProductsTable from '../products-table';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

interface ShowTransactionProps {
  transaction: Transaction;
  openDetails: boolean;
  hideBtn: () => void;
  lang: Language;
  currency: ICurrency;
  getLog: () => void;
  isDeposit?: boolean;
}

const ShowTransaction = ({
  transaction,
  openDetails,
  hideBtn,
  lang,
  currency,
  getLog,
  isDeposit
}: ShowTransactionProps) => {
  const currencies = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);
  const matchingCurrency = currencies?.find(c => c.cid === transaction.cid);

  //TODO - FIX ME
  if (transaction.eid === 10) {
    isDeposit = true;
  }

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={`${lang.TRANSACTIONS} ${transaction.eid}-${transaction.period}-${transaction.code}`}
        size={'65%'}
        content={
          <>
            <Container classNames={{ root: 'transaction-details' }}>
              <Group>
                <Grid gutter="sm">
                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <Title order={4}>{lang.USER_DELEGATION}</Title>
                    <GridCol>{getShopName(transaction.sid)}</GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <Title order={4}>{lang.EQUIPMENT_ID}</Title>
                    <GridCol>{transaction.eid}</GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <Title order={4}>{lang.GLOBAL_PERIOD}</Title>
                    <GridCol>{transaction.period}</GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <Title order={4}>{lang.GLASS_CODE}</Title>
                    <GridCol>{transaction.code}</GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <Title order={4}>{lang.GLOBAL_DATE_TIME}</Title>
                    <GridCol>{moment(transaction.dateTime).format('DD-MM-YYYY HH:mm:ss')}</GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <Title order={4}>{lang.GLOBAL_AMOUNT}</Title>
                    <GridCol>
                      {matchingCurrency ? formatCurrAmount(transaction.value || 0, matchingCurrency) : '-'}
                    </GridCol>
                  </GridCol>

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <Title order={4}>{lang.TRANSACTION_RECEIVED}</Title>
                    <GridCol>
                      {matchingCurrency ? formatCurrAmount(transaction.valueRec || 0, matchingCurrency) : '-'}
                    </GridCol>
                  </GridCol>

                  {!isDeposit && (
                    <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                      <Title order={4}>{lang.TRANSACTION_DISPENSED}</Title>
                      <GridCol>
                        {matchingCurrency ? formatCurrAmount(transaction.valueDis || 0, matchingCurrency) : '-'}
                      </GridCol>
                    </GridCol>
                  )}

                  {!isDeposit && (
                    <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                      <Title order={4}>{lang.TRANSACTION_PAYMENT_METHOD}</Title>
                      <GridCol>{handleTransactionMethod(lang, transaction.valueType)}</GridCol>
                    </GridCol>
                  )}

                  <GridCol span={{ sm: 12, md: 6, lg: 3 }}>
                    <Title order={4}>{lang.GLOBAL_TYPE}</Title>
                    <GridCol>{handleTransactionType(lang, transaction.type)}</GridCol>
                  </GridCol>
                </Grid>

                {transaction.products && transaction.products.length > 0 && (
                  <>
                    <strong>{lang.PRODUCT_DETAILS}</strong>
                    <ProductsTable lang={lang} products={transaction.products} />
                  </>
                )}

                {(transaction.cashIn ?? []).length > 0 && currency && (
                  <>
                    <CurrencyTable
                      lang={lang}
                      label={lang.CURRENCY_TABLE_CASH_IN}
                      currency={currency}
                      cash={transaction.cashIn}
                    />
                  </>
                )}

                {(transaction.cashOut ?? []).length > 0 && currency && (
                  <>
                    <CurrencyTable
                      lang={lang}
                      label={lang.CURRENCY_TABLE_CASH_OUT}
                      currency={currency}
                      cash={transaction.cashOut}
                    />
                  </>
                )}

                {(transaction.readValues ?? []).length > 0 && currency && (
                  <Accordion variant="filled" radius="xs">
                    <Accordion.Item value={lang.CURRENCY_TABLE_READING}>
                      <Accordion.Control>
                        <Flex justify="center">
                          <Title size="h4">{lang.CURRENCY_TABLE_READING}</Title>
                        </Flex>
                      </Accordion.Control>
                      <Accordion.Panel>
                        <CurrencyTable lang={lang} currency={currency} values={transaction.readValues} />
                      </Accordion.Panel>
                    </Accordion.Item>
                  </Accordion>
                )}

                <Button color="#016273" radius="md" onClick={() => getLog()}>
                  <Icon.IconDownload stroke={2} />
                  Logs
                </Button>
              </Group>
            </Container>
          </>
        }
      />
    </>
  );
};

export default ShowTransaction;

import { viLang_en } from './en';
import { viLang_pt } from './pt';

export type Language = { [key: string]: string };

export const getLanguageHandler = (langId?: number): Language => {
  if (langId === undefined) {
    langId = 0;
  }

  let langHandler;

  switch (langId) {
    case 1:
      langHandler = viLang_pt;
      break;
    case 2:
    default:
      langHandler = viLang_en;
      break;
  }

  const handler = {
    get: (target: any, prop: any) => {
      let value = target[prop];
      return value ? value : prop;
    }
  };

  return new Proxy(langHandler, handler);
};

import { useEffect, useState } from 'react';
import { Pagination, Combobox, useCombobox, InputBase, Group } from '@mantine/core';
import './styles.scss';

interface PaginationProps {
  total: number | undefined;
  statusOperCode?: number | undefined;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  onResultsChange: (newValue: number) => void;
  initialPage?: number;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  total = 0,
  statusOperCode,
  itemsPerPage,
  onPageChange,
  onResultsChange,
  initialPage
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [limit] = useState(itemsPerPage);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const values = [50, 100];
  const [value, setValue] = useState(values[0]);

  const comboBox = useCombobox({});

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage, offset]);

  useEffect(() => {
    if (statusOperCode === 0 && total !== null && total > 0 && itemsPerPage > 0) {
      setTotalPages(Math.ceil(total / itemsPerPage));
    } else {
      setTotalPages(1);
    }
  }, [total, itemsPerPage, statusOperCode]);

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
    setCurrentPage(page);
    onPageChange(page);
  };

  const handleItemsPerPageChange = (newValue: string) => {
    const parsedValue = parseInt(newValue, 10);
    setValue(parsedValue);
    onResultsChange(parsedValue);
    comboBox.closeDropdown();
  };

  const options = values.map((value: number) => (
    <Combobox.Option value={value.toString()} key={value}>
      {value}
    </Combobox.Option>
  ));

  return (
    <Group classNames={{ root: 'pagination' }} style={{ justifyContent: 'flex-end' }}>
      <Pagination radius="md" withEdges total={totalPages} value={currentPage} onChange={handlePageChange} />

      <Combobox
        classNames={{ dropdown: 'showValues' }}
        store={comboBox}
        withinPortal={false}
        onOptionSubmit={handleItemsPerPageChange}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            onClick={() => comboBox.toggleDropdown()}
            rightSectionPointerEvents="none"
          >
            {value}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Group>
  );
};

export default PaginationComponent;
